<template>
  <div>
    <b-card no-body class="mb-0">
      <b-card-body class="pt-0">
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Role</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="roleOptions"
              class="w-100"
              :reduce="(label) => label.code"
              @input="(label) => $emit('update:roleFilter', label)"
              placeholder="Select Role.."
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
              placeholder="Select Status.."
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) =>
      createElement("feather-icon", {
        props: {
          icon: "XIcon",
          svgClasses: "w-4 h-4 mt-1",
        },
      }),
  },
  OpenIndicator: {
    render: (createElement) =>
      createElement("feather-icon", {
        props: {
          icon: "ChevronDownIcon",
          svgClasses: "w-5 h-5",
        },
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    roleFilter: {
      type: [Number, 0],
      default: 0,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
